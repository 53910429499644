
import Vue from "vue";
import HomeService from "@/services/home.service";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';


import jQuery from "jquery";
let $ = jQuery;

export default Vue.extend({
  name: "App",
  data: () => ({
    instrumentationKey:"",
    claroOscuro: "",
    originBot: "",
    pais:"",
    urlRedirigir:"",
    heightTotal: document.documentElement.clientHeight,
  }),
  created() {
    this.cargaInicial();
  },
  mounted() {
    window.onresize = () => {
      this.resizeWindow();
    };
    this.resizeWindow();
    setTimeout(()=>{this.cargaInicialApplicationInsights();},1000)
    
  },
  methods: {
    deleteClassTheme(){
      setInterval(() => {
        var element:any = document.getElementsByClassName("theme--light ");
        for (var i = 0; i<element.length; i++) {
          element[i].classList.remove("theme--light");
        };
      }, 100);
    },
    resizeWindow: function () {
      $(window).scrollTop(0);
      this.heightTotal = document.documentElement.clientHeight;
    },
    cargaInicial() {
      HomeService.CargaInicial()
        .then((resp) => {
          localStorage.setItem("claroOscuro", resp.data.claroOscuro);
          localStorage.setItem("originBot", resp.data.originBot);
          localStorage.setItem("pais", resp.data.pais);
          localStorage.setItem("urlRedirigir", resp.data.urlRedirigir);
          localStorage.setItem("showVoice", resp.data.showVoice);
          localStorage.setItem("showDialogVoice", resp.data.showDialogVoice);
          localStorage.setItem("showVoiceAgent", resp.data.showVoiceAgent);
          localStorage.setItem("surveyLink", resp.data.surveyLink);
          this.urlRedirigir = resp.data.urlRedirigir;

          this.claroOscuro = resp.data.claroOscuro;
          this.originBot = resp.data.originBot;
          this.pais = resp.data.pais;
          this.instrumentationKey = resp.data.AppInsightInstrumentationKey;
          this.urlRedirigir = resp.data.urlRedirigir;
          if (resp.data.originBot == "TelcelBot") {
            $("#favicon").attr("href", "/icono-avatar-telcel-bot.png");
            document.title = "Asistente Virtual Telcel";
          } else {
            $("#favicon").attr("href", "/icon-avatar-claro-bot.png");
            document.title = "Asistente Virtual Claro";
          }
        })
        .catch((error) => {
          console.error("Error al realizar la carga inicial APP", error);
        });
    },
    cargaInicialApplicationInsights(){
        const clickPluginInstance = new ClickAnalyticsPlugin();
          // Click Analytics configuration
          const clickPluginConfig = {
            autoCapture: true
          };

          const configObj = {
            instrumentationKey: this.instrumentationKey,
            extensions: [clickPluginInstance],
            extensionConfig: {
              [clickPluginInstance.identifier]: clickPluginConfig
            },
          };

          const appInsights = new ApplicationInsights({ config: configObj });
          appInsights.loadAppInsights();
          appInsights.trackPageView();
      }
  },
});
