
/* eslint-disable */
import Vue from "vue";

import Header from "../components/Header.vue"
import HomeService from '../services/home.service'
export default Vue.extend({
  name: 'Home',
  components: {
    Header,
  },
  data() {
      return {
        claroOscuro:"",
        originBot:"",
        paginas:[],
        IsEnableClientTest:"true",
        userAgent:"",
      }
  },
  created(){
    HomeService.CargaInicial().then((resp) => {
        this.claroOscuro = resp.data.claroOscuro;
        this.originBot = resp.data.originBot;
        this.IsEnableClientTest = resp.data.IsEnableClientTest;
      }).catch((error) =>{
        console.error("Error al realizar la carga inicial Home",error);
      }
    );
  },
  mounted () {
    this.userAgent = navigator.userAgent.toLowerCase();
    console.log("userAgent:" + navigator.userAgent);
    this.cargaInicial()
  },
  methods: {
    cargaInicial: function () {
      HomeService.homeController().then((resp) => {
         this.paginas = resp.data;
        }).catch(err => {
            alert(err)
        });
    },
    popitup: function (url: any, windowName: any, data: any) {
        var dataurl = '?id=' + data;
        var rdn = Math.floor((Math.random() * 100) + 1);
        window.open(url + dataurl, windowName + rdn, 'height=640,width=450');
    }
  }
})
