
/* eslint-disable */
import Vue from "vue";
import $ from "jquery";
import "@/assets/CSS/EstiloGeneral.css";

export default Vue.component("Header",{
  props: {
    isChat: Boolean,
    claroOscuro:String,
    originBot:String,
    pais:String,
    
  },
  data() {
      return {
        isOscuro:false,
        imgLogo:"",
        disabledFinSesion:false,
      }
  },
  updated: function () {
    this.$nextTick(function () {
        if(this.originBot == "TelcelBot"){
            this.imgLogo = require('@/assets/Img/Telcel/icon-avatar.png');
        } else if(this.originBot == "ClaroBot") {
            this.imgLogo = require('@/assets/Img/Claro/icon-avatar.png');
        }
    })
  },
  mounted () {  
        if(this.originBot == "TelcelBot") {
            this.imgLogo = require('@/assets/Img/Telcel/icon-avatar.png');
        } else if(this.originBot == "ClaroBot") {
            this.imgLogo = require('@/assets/Img/Claro/icon-avatar.png');
        }
  },
  methods:{
    cambiaEstilo(){
        this.$emit('claroOscuro', this.isOscuro ? 'oscuro' : 'claro');
    }
  }
});
