import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import es from '@/plugins/es';

Vue.use(Vuetify);

Vue.use(Vuetify, {
    lang: {
      locales: {es},
      current: 'es'
    },
    theme: {
        primary: '',
        secondary: '',
        accent: '',
        error: '',
        info: '',
        success: '',
        warning: ''
    }
  })

export default new Vuetify({

});
