/* eslint-disable */
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Chat/IpBloqueada",
    name: "IpBloqueada",
    component: () => import("@/views/IpBloqueada.vue"),
  },
  {
    path: "/Chat/Chat",
    name: "valida",
    component: () => import("@/views/ValidChat.vue"),
  },
  {
    path: "/Chat/Chat/:id",
    name: "About",
    component: () => import("@/views/Chat.vue"),
  },
  {
    path: "/Chat/ChatFB",
    name: "ChatFB",
    component: () => import("@/views/Derivacion.vue"),
  },
  {
    path: "/Chat/ChatWab",
    name: "ChatWab",
    component: () => import("@/views/Derivacion.vue"),
  },
  {
    path: "/Chat/ChatAbc",
    name: "ChatAbc",
    component: () => import("@/views/Derivacion.vue"),
  },
  {
    path: "/Chat/Apple2Web",
    name: "Apple2Web",
    component: () => import("@/views/Derivacion.vue"),
  },
  {
    path: "/s/:id",
    name: "s",
    component: () => import("@/views/Derivacion.vue"),
  },
  {
    path: "/Chat/Chat/:keyChaatAnterior/:originBot",
    name: "Refresh",
    component: () => import("@/views/Derivacion.vue")
  },
  {
    path: "/Chat/Encuesta",
    name: "Encuesta",
    component: () => import("@/views/Encuesta.vue")
  },
  {
    path: "/Chat/FinalChat",
    name: "FinalChat",
    component: () => import("@/views/FinalChat.vue")
  },
  {
    path: "/ErrorDerivacionDirecta",
    name: "ErrorDerivacionDirecta",
    component: () => import("@/views/ErrorDerivacionDirecta.vue")
  },
  {
    path: "/Error",
    name: "Error",
    component: () => import("@/views/Error.vue")
  },
  {
    path: "*",
    name: "nof Found",
    component: () => import("@/views/Error.vue")
  }
   
];

const router = new VueRouter({
  mode:"history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
