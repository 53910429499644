/* eslint-disable */
import axios from 'axios'
import {URL} from './URL_SERVICIOS'
const API_URL = URL.PINCIPAL

class HomeService {
  homeController (){
    return axios.get(API_URL+'/api/Home/CargaInicial');
  }
  CargaInicial () {
    return axios.get(API_URL+'/api/Home/CargaInicialChat');
  }
}
export default new HomeService()