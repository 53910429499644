/* eslint-disable */

const URL_AMBIENTE = new Map();
var URLdomain = window.location.host;

function mexico(){
    /**
     * SERVER  DEV */
    URL_AMBIENTE.set("scd-de-mx-livechat-01-b993.azurewebsites.net","https://scd-de-mx-livechat-08-b993.azurewebsites.net");
    
     /**SERVER  UAT */
    URL_AMBIENTE.set("scd-te-mx-livechat-01-34e4.azurewebsites.net","https://scd-te-mx-livechat-08-34e4.azurewebsites.net");
    URL_AMBIENTE.set("scdde.net","https://scd-te-mx-livechat-08-34e4.azurewebsites.net");
       
    
    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("americamovil-mx-livechat-client-uat.azurewebsites.net","https://amx-mx-ase-livechat-client-service-uat.azurewebsites.net");
    
    /**SERVER PROD */
    URL_AMBIENTE.set("americamovil-mx-livechat-client-pro.azurewebsites.net","https://amx-mx-ase-livechat-client-service-pro.azurewebsites.net");
    URL_AMBIENTE.set("scdmx.net","https://amx-mx-ase-livechat-client-service-pro.azurewebsites.net");
}

function costarica(){
    /** Costarica */
    URL_AMBIENTE.set("scd-te-cr-livechat-01-3f55.azurewebsites.net","https://scd-te-cr-livechat-08-3f55.azurewebsites.net");    
    URL_AMBIENTE.set("scddecr.net","https://scd-te-cr-livechat-08-3f55.azurewebsites.net");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("amx-cr-ase-livechat-client-uat.azurewebsites.net","https://amx-cr-ase-livechat-client-service-uat.azurewebsites.net");

    /**SERVER PROD */
    URL_AMBIENTE.set("amx-cr-ase-livechat-client-pro.azurewebsites.net","https://amx-cr-ase-livechat-client-service-pro.azurewebsites.net");    
    URL_AMBIENTE.set("scdcr.net","https://amx-cr-ase-livechat-client-service-pro.azurewebsites.net");
}

function nicaragua(){
    /** NICARAGUA */
    URL_AMBIENTE.set("scd-te-ni-livechat-01-1dbf.azurewebsites.net","https://scd-te-ni-livechat-08-1dbf.azurewebsites.net");    
    URL_AMBIENTE.set("scddeni.net","https://scd-te-ni-livechat-08-1dbf.azurewebsites.net");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("amx-ni-ase-livechat-client-uat.azurewebsites.net","https://amx-ni-ase-livechat-client-service-uat.azurewebsites.net");

    /**SERVER PROD */
    URL_AMBIENTE.set("amx-ni-ase-livechat-client-pro.azurewebsites.net","https://amx-ni-ase-livechat-client-service-pro.azurewebsites.net");    
    URL_AMBIENTE.set("scdni.net","https://amx-ni-ase-livechat-client-service-pro.azurewebsites.net");
}

function ecuador(){
    /** ECUADOR */
    URL_AMBIENTE.set("scd-te-ec-livechat-01-328a.azurewebsites.net","https://scd-te-ec-livechat-08-328a.azurewebsites.net");    
    URL_AMBIENTE.set("scddeec.net","https://scd-te-ec-livechat-08-328a.azurewebsites.net");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("amx-ec-ase-livechat-client-uat.azurewebsites.net","https://amx-ec-ase-livechat-client-service-uat.azurewebsites.net");

    /**SERVER PROD */
    URL_AMBIENTE.set("amx-ec-ase-livechat-client-pro.azurewebsites.net","https://amx-ec-ase-livechat-client-service-pro.azurewebsites.net");    
    URL_AMBIENTE.set("scdecs.net","https://amx-ec-ase-livechat-client-service-pro.azurewebsites.net");
}

function puertoRico(){
    URL_AMBIENTE.set("scd-te-pr-livechat-01-8cfa.azurewebsites.net","https://scd-te-pr-livechat-08-8cfa.azurewebsites.net");
    URL_AMBIENTE.set("scddepr.net","https://scd-te-pr-livechat-08-8cfa.azurewebsites.net");
    
    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("amx-pr-ase-livechat-client-uat.azurewebsites.net","https://amx-pr-ase-livechat-client-service-uat.azurewebsites.net");

    /**SERVER PROD */
    URL_AMBIENTE.set("amx-pr-ase-livechat-client-pro.azurewebsites.net","https://amx-pr-ase-livechat-client-service-pro.azurewebsites.net");    
    URL_AMBIENTE.set("scdpr.net","https://amx-pr-ase-livechat-client-service-pro.azurewebsites.net");
}

function honduras(){
    /** NICARAGUA */
    URL_AMBIENTE.set("scd-te-hn-livechat-01-273e.azurewebsites.net","https://scd-te-hn-livechat-08-273e.azurewebsites.net");    
    URL_AMBIENTE.set("scddehn.net","https://scd-te-hn-livechat-08-273e.azurewebsites.net");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("amx-hn-ase-livechat-client-uat.azurewebsites.net","https://amx-hn-ase-livechat-client-service-uat.azurewebsites.net");

    /**SERVER PROD */
    URL_AMBIENTE.set("amx-hn-ase-livechat-client-pro.azurewebsites.net","https://amx-hn-ase-livechat-client-service-pro.azurewebsites.net");    
    URL_AMBIENTE.set("scdhn.net","https://amx-hn-ase-livechat-client-service-pro.azurewebsites.net");
}

function guatemala()
{
    URL_AMBIENTE.set("scd-te-gt-livechat-01-c48d.azurewebsites.net","https://scd-te-gt-livechat-08-c48d.azurewebsites.net");    
    URL_AMBIENTE.set("scddegt.net","https://scd-te-gt-livechat-08-c48d.azurewebsites.net");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("amx-gt-ase-livechat-client-uat.azurewebsites.net","https://amx-gt-ase-livechat-client-service-uat.azurewebsites.net");

    /**SERVER PROD */
    URL_AMBIENTE.set("amx-gt-ase-livechat-client-pro.azurewebsites.net","https://amx-gt-ase-livechat-client-service-pro.azurewebsites.net");    
    URL_AMBIENTE.set("scdgt.net","https://amx-gt-ase-livechat-client-service-pro.azurewebsites.net");
}

function chile()
{
    URL_AMBIENTE.set("scd-te-cl-livechat-01-ef7f.azurewebsites.net","https://scd-te-cl-livechat-08-ef7f.azurewebsites.net/");    
    URL_AMBIENTE.set("scddecl.net","https://scd-te-cl-livechat-08-ef7f.azurewebsites.net/");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("amx-cl-ase-livechat-client-uat.azurewebsites.net","https://amx-cl-ase-livechat-client-service-uat.azurewebsites.net");

    /**SERVER PROD */
    URL_AMBIENTE.set("amx-cl-ase-livechat-client-pro.azurewebsites.net","https://amx-cl-ase-livechat-client-service-pro.azurewebsites.net");    
    URL_AMBIENTE.set("scdcls.net","https://amx-cl-ase-livechat-client-service-pro.azurewebsites.net");
}

function peru()
{
    URL_AMBIENTE.set("scd-te-pe-livechat-01-ecd4.azurewebsites.net","https://scd-te-pe-livechat-08-ecd4.azurewebsites.net");    
    URL_AMBIENTE.set("scddepe.net","https://scd-te-pe-livechat-08-ecd4.azurewebsites.net");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("<dominio de azure EAS UAT de livechat-client>","https://<dominio de azure EAS UAT de livechat-client-service>");

    /**SERVER PROD */
    URL_AMBIENTE.set("<dominio de azure EAS PROD de livechat-client>","https://<dominio de azure EAS PROD de livechat-client-service>");    
    URL_AMBIENTE.set("<dominio URL corta>","https://<dominio de azure EAS PROD de livechat-client-service>");
}

function salvador()
{
    URL_AMBIENTE.set("scd-te-sv-livechat-01-4966.azurewebsites.net","https://scd-te-sv-livechat-08-4966.azurewebsites.net");    
    URL_AMBIENTE.set("scddesv.net","https://scd-te-sv-livechat-08-4966.azurewebsites.net");

    /**SERVER  UAT EAS*/
    URL_AMBIENTE.set("<dominio de azure EAS UAT de livechat-client>","https://<dominio de azure EAS UAT de livechat-client-service>");

    /**SERVER PROD */
    URL_AMBIENTE.set("<dominio de azure EAS PROD de livechat-client>","https://<dominio de azure EAS PROD de livechat-client-service>");    
    URL_AMBIENTE.set("<dominio URL corta>","https://<dominio de azure EAS PROD de livechat-client-service>");
}

/**SERVER  Local */
URL_AMBIENTE.set("localhost:8080","https://scd-te-ni-livechat-08-1dbf.azurewebsites.net")

mexico();
costarica();
nicaragua();
ecuador();
honduras();
guatemala();
puertoRico();
chile();
salvador();
peru();




console.log("Path: "+URLdomain +"\nController: " + URL_AMBIENTE.get(URLdomain));

export const URL = { PINCIPAL: URL_AMBIENTE.get(URLdomain) };






